import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getOriginalImageSrc } from '../../../utils/formatter';
import { StyledLink } from '../../../utils/ui';
import { MAX_WIDTH } from '../../../utils/constants';

const HeroFeaturedProductTypesWrapper = styled.div`
  padding-top: 91px;
  .featuredproducttypes-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 35px;
    column-gap: 33px;
    margin-top: 92px;
  }
  .featuredproducttypes-item-background {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .featuredproducttypes-item {
    width: 100%;
    position: relative;
  }
  .featuredproducttypes-item-content {
    position: absolute;
    background: #ffffff;
    bottom: 28px;
    left: 45px;
    right: 45px;
    padding: 22px 44px 26px 44px;
  }
  .featuredproducttypes-item-name {
  }
  .featuredproducttypes-item-description {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 134.5%;
    /* or 17px */

    letter-spacing: 0.05em;

    color: #000000;
  }
  .featuredproducttypes-item-viewmore {
    text-transform: uppercase;

    color: #A88C69;
  }
  .featuredproducttypes-readmore {
    margin: 55px auto;
    margin-bottom: 80px;
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    padding: 16px;
    padding-top: 32px;
    .featuredproducttypes-list {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      row-gap: 35px;
      column-gap: 0px;
      margin-top: 32px;
    }
    .featuredproducttypes-item-content {
      bottom: 16px;
      left: 24px;
      right: 24px;
      padding: 16px 24px 16px 24px;
    }
    .featuredproducttypes-readmore {
      margin: 36px 0px;
    }
  }
  .featuredproducttypes-list:hover .featuredproducttypes-item:not(:hover) {
    opacity: 0.4;
    @media screen and (max-width: ${MAX_WIDTH}) {
      opacity: 1;
    }
  }
  .featuredproducttypes-list .featuredproducttypes-item {
    transition: opacity 0.25s ease-in-out;
    opacity: 1;
  }
`;

const HeroFeaturedProductTypes = ({ featured_producttypes }) => {
  const { title, product_types, readmore_url } = featured_producttypes;
  return (
    <HeroFeaturedProductTypesWrapper id="featured-product-types">
      <div>
        <h1 className="text_center color_dark_grey">{title}</h1>
        <div className="border" />
      </div>
      <div className="featuredproducttypes-list">
        {product_types.map((product_type) => {
          const { name, description, producttype_thumbnail, url } = product_type;
          return (
            <StyledLink key={name} className="featuredproducttypes-item" to={url}>
              <img
                className="featuredproducttypes-item-background"
                src={getOriginalImageSrc(producttype_thumbnail)}
                alt={name}
              />
              <div className="featuredproducttypes-item-content">
                <h3 className="featuredproducttypes-item-name">{name}</h3>
                <p className="featuredproducttypes-item-description">{description}</p>
                <a className="featuredproducttypes-item-viewmore">XEM THÊM</a>
              </div>
            </StyledLink>
          );
        })}
      </div>
      <a className="button read_more featuredproducttypes-readmore" href={readmore_url}>
        Xem thêm
      </a>
    </HeroFeaturedProductTypesWrapper>
  );
};

HeroFeaturedProductTypes.propTypes = {};

export default HeroFeaturedProductTypes;
