import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StyledLink } from '../../../utils/ui';
import { getOriginalImageSrc } from '../../../utils/formatter';
import { MAX_WIDTH } from '../../../utils/constants';

const HeroBlogWrapper = styled.div`
  max-width: ${MAX_WIDTH};
  .home-fragment-title-description {
    border-bottom-width: 0px;
  }
  .heroblogs-blogs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .blog-item {
    padding-bottom: 47px;
    border-bottom: 1px solid #a88c69;
  }
  .blog-item:first-child {
    border-right: 1px solid #a88c69;
    padding-right: 71px;
  }
  .blog-item:nth-child(2) {
    padding-left: 71px;
  }
  .blog-item-readmore {
    line-height: 32px;
    letter-spacing: 0.15em;
    color: #a88c69 !important;
  }
  .blog-item-readmore:hover {
    text-decoration-line: underline;
  }
  .blog-item-thumbnail {
    height: 220px;
    width: 100%;
    object-fit: cover;
  }
  .heroblogs-content-readmore {
    margin: 80px auto;
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    padding: 16px;
    padding-top: 32px;
    .heroblogs-blogs {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      row-gap: 35px;
      column-gap: 0px;
      margin-top: 32px;
    }
    .heroblogs-content-readmore {
      margin: 36px 0px;
    }
    .blog-item:first-child {
      border-right-width: 0px;
      padding-right: 0px;
    }
    .blog-item:nth-child(2) {
      padding-left: 0px;
    }
    .blog-item {
      padding-bottom: 16px;
      border-bottom-width: 0px;
    }
  }
`;

const BlogItem = ({ blog }) => {
  const { title, description, slug, blog_thumbnail } = blog;
  return (
    <StyledLink className="blog-item" to={`/blog/${slug}`}>
      <img className="blog-item-thumbnail" src={getOriginalImageSrc(blog_thumbnail)} alt={title} />
      <h3 className="blog-item-title">{title}</h3>
      <p className="blog-item-description large">{description}</p>
      <StyledLink className="blog-item-readmore">ĐỌC THÊM +</StyledLink>
    </StyledLink>
  );
};

const HeroBlog = ({ featured_blogs }) => {
  const { title, readmore_url, blogs } = featured_blogs;
  return (
    <HeroBlogWrapper>
      <div>
        <h6 className="color_light_brown text_center">BẢO TÀNG</h6>
        <h1 className="color_dark_grey text_center">{title}</h1>
      </div>
      <div className="separator" />
      <div className="heroblogs-content">
        <div className="heroblogs-blogs">
          {blogs.map((blog) => (
            <BlogItem key={blog.id} blog={blog} />
          ))}
        </div>
        <a className="heroblogs-content-readmore button read_more" href={readmore_url}>
          Xem thêm
        </a>
      </div>
    </HeroBlogWrapper>
  );
};

BlogItem.propTypes = {
  blog: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
    blog_thumbnail: PropTypes.object,
  }),
};

HeroBlog.propTypes = {
  featured_blogs: PropTypes.shape({
    title: PropTypes.string,
    readmore_url: PropTypes.string,
    blogs: PropTypes.array,
  }),
};

export default HeroBlog;
