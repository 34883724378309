import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getOriginalImageSrc } from '../../../utils/formatter';
import { MAX_WIDTH } from '../../../utils/constants';

const HeroAboutUsWrapper = styled.div`
  padding-top: 81px;
  .heroaboutus-content-section {
    padding-top: 81px;
    display: flex;
  }
  .heroaboutus-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .heroaboutus-content-description {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;

    color: #292929;
  }
  .heroaboutus-image {
    margin-left: 71px;
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    padding-top: 32px;
    .border {
      margin: 0px 16px;
    }
    .heroaboutus-content-section {
      padding: 16px;
      display: flex;
      flex-direction: column-reverse;
    }
    .heroaboutus-image {
      margin-left: 0px;
    }
    .heroaboutus-content-description {
      padding-top: 16px;
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const HeroAboutUs = ({ hero_aboutus }) => {
  const { author, title, description, image, readmore_link } = hero_aboutus;
  return (
    <HeroAboutUsWrapper id="about-us " className="max_width ">
      <div>
        <h6 className="color_light_brown text_center">{author}</h6>
        <h2 className="color_dark_grey text_center">{title}</h2>
        <div className="border " />
      </div>
      <div className="heroaboutus-content-section">
        <div className="heroaboutus-content">
          <p className="large" dangerouslySetInnerHTML={{ __html: description }}></p>
          <a className="button read_more" href={readmore_link}>
            Đọc thêm
          </a>
        </div>
        <img className="heroaboutus-image" src={getOriginalImageSrc(image)} alt={title} />
      </div>
    </HeroAboutUsWrapper>
  );
};

HeroAboutUs.propTypes = {
  hero_aboutus: PropTypes.shape({
    author: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
    readmore_link: PropTypes.string,
  }),
};

export default HeroAboutUs;
