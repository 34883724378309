import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import brands_bkg from '../../../assets/brands_bkg.png';
import { getOriginalImageSrc } from '../../../utils/formatter';
import Slider from '../../common/Slider.js';
import { MAX_WIDTH } from '../../../utils/constants';
const HeroPartnersWrapper = styled.div`
  width: 100%;
  .home-fragment-title-description {
    border-bottom-width: 0px;
  }
  .heropartners-brands {
    position: relative;
    overflow: auto;
    padding: 0px 0px;
    background-size: cover;
  }
  .brand-item {
    padding-bottom: 110px;
    padding-top: 25px;
  }
  .heropartners-content {
    background-image: url(${brands_bkg});
  }
  .heropartners-title {
    font-family: Prata;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 160%;
    text-align: center;
    padding-top: 72px;
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    .brand-item {
      padding-bottom: 80px;
      padding-top: 35px;
      width: 50%;
    }
    .heropartners-title {
      padding-top: 46px;
      line-height: 150%;
      font-size: 28px;
      margin: 0px 90px 0 90px;
    }
  }
`;

const BrandItem = ({ brand }) => {
  const { url, logo, name } = brand;
  return (
    <a className="brand-item content_center_row" href={url} target="_blank" rel="noreferrer">
      <img className="brand-item-img" src={getOriginalImageSrc(logo)} alt={name} />
    </a>
  );
};

const HeroPartners = ({ featured_brands }) => {
  const { title, featured_brands_item } = featured_brands;
  return (
    <HeroPartnersWrapper>
      <div className="heropartners-content">
        <h1 className="heropartners-title color_white">{title}</h1>
        <div className="heropartners-brands content_center_row">
          <Slider>
            {featured_brands_item.map((brand) => (
              <BrandItem key={brand.id} brand={brand} />
            ))}
          </Slider>
        </div>
      </div>
    </HeroPartnersWrapper>
  );
};
BrandItem.propTypes = {
  brand: PropTypes.shape({
    url: PropTypes.string,
    logo: PropTypes.object,
    name: PropTypes.string,
  }),
};
HeroPartners.propTypes = {
  featured_brands: PropTypes.shape({
    title: PropTypes.string,
    featured_brands_item: PropTypes.array,
  }),
};

export default HeroPartners;
