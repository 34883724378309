import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const query = graphql`
  query PageHome {
    strapiPageHome {
      banner_text {
        title
        background {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
      hero_aboutus {
        author
        title
        description
        image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        readmore_link
      }
      image_seethrough {
        image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
      featured_producttypes {
        title
        product_types {
          name
          description
          producttype_thumbnail {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          url
        }
        readmore_url
      }
      featured_products {
        title
        readmore_url
        products {
          id
          name
          slug
          product_thumbnail {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
      featured_blogs {
        title
        readmore_url
        id
        blogs {
          id
          slug
          title
          description
          blog_thumbnail {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
      featured_brands {
        title
        featured_brands_item {
          id
          name
          url
          logo {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
      hero_contactus {
        description
        title
        url
      }
    }
  }
`;

export default function PageHome({ renderContent }) {
  return <StaticQuery query={query} render={(data) => renderContent(data)} />;
}
