import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MAX_WIDTH } from '../../../utils/constants';
import { StyledLink } from '../../../utils/ui';
import { getOriginalImageSrc } from '../../../utils/formatter';

const HeroFeaturedProductsWrapper = styled.div`
  padding: 16px;
  .herofeaturedproducts-products {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .herofeaturedproducts-content {
    padding-top: 106px;
  }
  .product-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .product-item-img-frame {
    width: 232px;
    height: 343px;
    box-sizing: border-box;
    border: 15px solid #ddc4a4;
    position: absolute;
    z-index: 10;
  }
  .product-item-img {
    width: auto;
    height: 328px;
    z-index: 12;
    position: relative;
    margin-top: 94px;
  }
  .product-item-name {
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.105em;
    text-transform: uppercase;

    color: #292929;
  }
  .herofeaturedproducts-content-readmore {
    margin: 80px auto;
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    padding: 16px;
    .herofeaturedproducts-products {
      margin-top: 26px;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
    .herofeaturedproducts-content-readmore {
      margin: 36px auto;
    }
  }
  .herofeaturedproducts-products:hover .product-item:not(:hover) {
    opacity: 0.4;
    @media screen and (max-width: ${MAX_WIDTH}) {
      opacity: 1;
    }
  }
  .herofeaturedproducts-products .product-item {
    transition: opacity 0.25s ease-in-out;
    opacity: 1;
  }
`;

const ProductItem = ({ product }) => {
  const { name, slug, product_thumbnail } = product;
  return (
    <StyledLink className="product-item" to={`/product/${slug}`}>
      <div className="product-item-img-frame" />
      <img className="product-item-img" src={getOriginalImageSrc(product_thumbnail)} alt={name} />
      <h3 className="product-item-name">{name}</h3>
    </StyledLink>
  );
};

const HeroFeaturedProducts = ({ featured_products }) => {
  const { title, readmore_url, products } = featured_products;
  return (
    <HeroFeaturedProductsWrapper className="max_width full_width">
      <div className="padding_content">
        <h6 className="color_light_brown text_center">{title}</h6>
        <h1 className="color_dark_grey text_center">SẢN PHẨM RƯỢU NỔI BẬT</h1>
        <div className="border" />
        <div className="herofeaturedproducts-content">
          <div className="herofeaturedproducts-products">
            {products.map((product) => (
              <ProductItem key={product.id} product={product} />
            ))}
          </div>
          <a className="herofeaturedproducts-content-readmore button read_more" href={readmore_url}>
            Đọc thêm
          </a>
        </div>
      </div>
    </HeroFeaturedProductsWrapper>
  );
};

ProductItem.propTypes = {
  product: PropTypes.object,
};

HeroFeaturedProducts.propTypes = {
  featured_products: PropTypes.object,
};

export default HeroFeaturedProducts;
