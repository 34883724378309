import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MAX_WIDTH } from '../../../utils/constants';
import arrowDownCircle from '../../../assets/arrow-down-circle.svg';

const ContactUsWrapper = styled.div`
  width: 100%;
  padding: 98px 0px 130px 0px;
  background-color: #A88C69;
  .contactus-title-button {
    margin: 63px auto;
  }
  .left-arrow {
    transform: rotate(-90deg);
  }
  .contactus-title {
    color: #E1DFDB;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .contactus-description {
    color: #E1DFDB;
    font-family: Prata;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 160%;
    text-align: center;
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    padding: 16px 16px;
    .contactus-title {
      font-size: 13px;
      line-height: 16px;
    }
    .contactus-description {
      font-size: 32px;
      line-height: 36px;
      margin-left: 40px;
      margin-right: 40px;
    }
  }
`;

const ContactUs = ({ hero_contactus }) => {
  const { description, title, url } = hero_contactus;
  return (
    <ContactUsWrapper>
      <div className="padding_content">
        <h6 className="contactus-title">{title}</h6>
        <h1 className="contactus-description">{description}</h1>

        <a className="contactus-title-button content_center" href={url}>
          <img className="left-arrow" src={arrowDownCircle} alt="" />
        </a>
      </div>
    </ContactUsWrapper>
  );
};

ContactUs.propTypes = {};

export default ContactUs;
