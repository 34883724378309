import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PageHome from '../queries/page-home';
import 'fullpage.js/dist/fullpage.min.css';
import 'fullpage.js/vendors/scrolloverflow.js';
import SiteLayout from '../components/SiteLayout';
import { BannerKeyVisual, ImageSeeThrough, Footer } from '../components/common/';
import {
  HeroAboutUs,
  HeroFeaturedProducts,
  HeroFeaturedProductTypes,
  HeroBlog,
  HeroPartners,
  ContactUs,
} from '../components/page/HomePage';
import { getOriginalImageSrc } from '../utils/formatter';
import { useScrolldown } from '../hooks/useScrolldown';

const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .main_section5 {
    padding-top: 32px;
  }
`;

export default function Home() {
  const { onScrollDown } = useScrolldown('#banner-key-visual');

  const renderContent = ({ strapiPageHome }) => {
    const {
      banner_text,
      hero_aboutus,
      image_seethrough,
      featured_producttypes,
      featured_products,
      map_image,
      featured_blogs,
      featured_brands,
      hero_contactus,
    } = strapiPageHome;
    return (
      <HomeWrapper className="background_color_theme">
        <BannerKeyVisual banner_text={banner_text} onScrollDown={onScrollDown} />
        <HeroAboutUs hero_aboutus={hero_aboutus} />
        <ImageSeeThrough imageUrl={getOriginalImageSrc(image_seethrough?.image)} height={'260px'} />
        <HeroFeaturedProductTypes featured_producttypes={featured_producttypes} />
        <HeroFeaturedProducts featured_products={featured_products} />
        <ImageSeeThrough imageUrl={getOriginalImageSrc(map_image?.image)} height={'440px'} />
        <HeroBlog featured_blogs={featured_blogs} />
        <HeroPartners featured_brands={featured_brands} />
        <ContactUs hero_contactus={hero_contactus} />
        <Footer />
      </HomeWrapper>
    );
  };

  return (
    <SiteLayout isHomePage activeKey={'HOME'}>
      <PageHome renderContent={renderContent} />
    </SiteLayout>
  );
}
